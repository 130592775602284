<template>
  <FormInputSelect :id="id" :value="value" @input="$emit('input', $event)" :options="perPageOptions" />
</template>

<script>

export default {
  name: 'per-page-selector',
  components: {
    FormInputSelect: () => import('../../Form/components/Inputs/FormInputSelect'),
  },
  props: {
    id: {
      type: String,
    },
    value: {},
    options: {
      type: Array,
      default: () => [15, 30, 50],
    },
  },
  computed: {
    perPageOptions () {
      return this.options.map(p => ({
        label: p,
        value: p,
      }));
    },
  },
};

</script>

<style lang="scss"></style>
